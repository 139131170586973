import { render, staticRenderFns } from "./VerifyEmailBeforeLogin.vue?vue&type=template&id=374b8196&scoped=true"
import script from "./VerifyEmailBeforeLogin.vue?vue&type=script&lang=js"
export * from "./VerifyEmailBeforeLogin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "374b8196",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runcloud/webapps/demo-2-jetxplatform/releases/20250327105855/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('374b8196')) {
      api.createRecord('374b8196', component.options)
    } else {
      api.reload('374b8196', component.options)
    }
    module.hot.accept("./VerifyEmailBeforeLogin.vue?vue&type=template&id=374b8196&scoped=true", function () {
      api.rerender('374b8196', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/modals/VerifyEmailBeforeLogin.vue"
export default component.exports